<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Serviços de Terceiros Detalhados
    </v-card-title>
    <master-detail
      asyncExportType="terceiros"
      :cols="colunas"
      :canDelete="false"
      :canEdit="false"
      hasBeneficioFilter
      :hasExportCSV="false"
      hasMonthlyFilter
      :hasNewButton="false"
      :opts="opts"
      :resourceUrl="resourceUrl"
      :serverPagination="true"
      @click="rowClick"
    >
      <div class="d-flex">
        <calculation-status-menu
          beneficio
          class="table-v-action-button mr-3"
          icon="mdi-alpha-t-box-outline"
          name="Status de Terceiros"
          type="terceiros"
        ></calculation-status-menu>
        <servico-select
          class="table-v-action-button mr-3 mb-n1 mt-n1 mr-3"
          style="max-width: 255px"
        ></servico-select>
      </div>
    </master-detail>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { AnyClient } from '@/core/enums/user-types';

export default {
  components: {
    "calculation-status-menu": () => import("@/components/calculation-status-menu.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'user']),
    isClient() {
      return AnyClient.includes(this.user.tipo_usuario);
    },
    resourceUrl: function () {
      return `/v1/fiscal/nfservico/${this.clientId}/detalhado`;
    },
    colunas: function () {
      return [
        { name: "NF", key: "nf", filterable: true },
        { name: "Data", key: "data", type: this.$fieldTypes.DATE },
        {
          name: "Competência",
          key: "competencia",
          type: this.$fieldTypes.MONTH,
        },
        {
          name: "Mês Prestação Serviço",
          key: "mes_base",
          type: this.$fieldTypes.MONTH,
        },
        { name: "Razão Social", key: "razao_social", filterable: true },
        {
          name: "Critério Rateio",
          key: "criterio_rateio",
          data: "criterio_rateio",
        },
        { name: "Consultor", key: "consultor" },
        {
          name: "CNPJ/CPF",
          key: "cnpj",
          type: this.$fieldTypes.CNPJ,
        },
        { name: "Enquadramento", key: "enquadramento" },
        { name: "Código de serviço da NF", key: "codigo_servico_nf" },
        { name: "Descrição NF", key: "descricao" },
        {
          name: "Valor Total NF",
          key: "total_nf",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
        },
        {
          name: "Horas Trabalhadas",
          key: "total_horas",
          type: this.$fieldTypes.DOUBLE,
        },
        {
          name: "Horas P&D",
          key: "horas_ped",
          type: this.$fieldTypes.DOUBLE,
        },
        { name: "Alocação NF (%)", key: "alocacao" },
        {
          name: "Valor Utilizado P&D",
          key: "valor",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
        },
        { name: "Projeto", key: "projeto", filterable: true },
        { name: "Classificação", key: "finalidade" },
        { name: "Justificativa", key: "justificativa" },
        { name: "Atividade", key: "atividade" },
        { name: "P&D", key: "ped" },
        {
          key: "jobId",
          name: "ID Importação",
        },
      ];
    },
  },
  data: function () {
    return {
      opts: {
        criterio_rateio: {
          1: "Interno",
          2: "Terceiro",
        },
      },
    };
  },
  methods: {
    rowClick: function ({ nfId, itemId, criterio_rateio }) {
      if (this.isClient) return;
      const rateioEnum = {
        INTERNO: 'Interno',
        TERCEIRO: 'Terceiro',
      }
      let routeData = this.$router.resolve({
        name: "dispendio-servicos-terceiros-detalhes",
        params: { nfId },
        query: {
          itemId,
          criterio_rateio: criterio_rateio == rateioEnum.TERCEIRO ? 2 : 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
